<template>
  <div>
    <app-list class="ticket-types-list" request-url="/api/tickets/types/table" entity="ticket-types"/>

    <portal to="subheader-toolbar">
      <b-button variant="transparent-white" @click="$router.push({name: 'createTicketType'})">
        {{ $t('btn.create_ticket_type') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppList from '@/components/lists/AppList';
import AppModal from '@/components/modals/AppModal';

export default {
  components: {
    AppCard,
    AppList,
    AppModal
  }
}
</script>

<style lang="scss" scoped>
.ticket-types-list::v-deep {
  & .bg-secondary {
    display: none;
  }
}
</style>